<template>
  <div class="p-8 pt-0">
    <h1>Inloggen</h1>
    <rxf-form :schema="schema" :submit="login"></rxf-form>
  </div>
</template>
<script setup lang="ts">
import type { FormKitNode } from '@formkit/core';

definePageMeta({ auth: { unauthenticatedOnly: true, navigateAuthenticatedTo: '/' } });
const schema = [
  {
    $formkit: 'text',
    name: 'username',
    label: 'Gebruikersnaam',
    validation: 'required',
  },
  {
    $formkit: 'password',
    name: 'password',
    label: 'Wachtwoord',
    validation: 'required',
  },
  {
    $formkit: 'submit',
    name: 'inloggen',
    label: 'Inloggen',
  },
];
const { signIn } = useAuth();

async function login(data: any, node: FormKitNode) {
  try {
    await signIn('credentials', {
      username: data.username,
      password: data.password,
      redirect: true,
      callbackUrl: '/',
    });
  } catch (error) {
    // signIn gooit bij een localprovider een exception indien de server een 401 teruggeeft.
    // Het zou mogelijk moeten zijn om via redirect: false in de signIn opties mee te geven netjes de error af te handlen, maar
    // dit lijkt niet te werken bij gebruik van een local provider.
    node.setErrors(
      'De combinatie gebruikersnaam en wachtwoord is onjuist. Controleer de gegevens. Houd rekening met hoofdletters en kleine letters.'
    );
  }
}
</script>
